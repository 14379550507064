<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl" data-cy="account-info">
      Reset Email Verification
    </h1>
    <div class="flex flex-wrap mb-14 justify-start">
      <BaseInput
        class="text-theme-darkest mr-4"
        label="Verification Code"
        placeholder="Code"
        v-model="code"
        type="text"
      />
      <div class="text-center">
        <button class="mt-8" @click="submitCode" :disabled="!this.code">
          <span v-if="loading">{{ loadingMessage }}</span>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
    <p class="error" v-if="formError">{{ formError }}</p>
    <a class="mt-8 cursor-pointer" @click="resendCode">
      Resend Verification Code
    </a>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data: function () {
    return {
      code: '',
      formError: ''
    };
  },
  methods: {
    async resendCode () {
      this.formError = '';
      try {
        const resendCode = await Auth.verifyCurrentUserAttribute('email');
        console.log(resendCode);
      } catch (err) {
        this.formError = err.message;
      }
    },
    async submitCode () {
      this.formError = '';
      try {
        const verify = await Auth.verifyCurrentUserAttributeSubmit(
          'email',
          this.code
        );
        if (verify === 'SUCCESS') {
          const session = await Auth.currentSession();
          const userInfo = await Auth.currentAuthenticatedUser({
            bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          });
          this.$store.dispatch('updateUser', { ...session, ...userInfo });
          this.$router.push('/account-info');
        }
      } catch (err) {
        this.formError = err.message;
      }
    }
  }
};
</script>

<style lang="scss"></style>
